/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "Albert_Sans";
  src: local("Albert_Sans"),
    url(./font/Albert_Sans/AlbertSans-VariableFont_wght.ttf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
}
.circle1 {
  height: 21px;
  width: 21px;
  margin-left: -150px;
  margin-top: 450px;
  background-color: #0c31ff;
  border-radius: 50%;
  display: inline-block;
}
.circle2 {
  height: 21px;
  width: 21px;
  margin-left: 450px;
  margin-top: 450px;
  background-color: #0c31ff;
  border-radius: 50%;
  display: inline-block;
}
.circle3 {
  height: 21px;
  width: 21px;
  margin-left: 450px;
  margin-top: 450px;

  background-color: #0c31ff;
  border-radius: 50%;
  display: inline-block;
}

.img-wrapper {
  max-height: 100px;
  overflow: hidden;
}
.img-wrapper img {
  width: 50px;
}

.title {
  margin-top: "303px";
  margin-left: "250px";
  width: "382px";
  height: "66px";
  position: "absolute";
  color: "#FFFFFF";
  font-family: "Albert";
  font-size: "55px";
}
